import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../services/API";

const AppContext = createContext({
  redes: [],
  lojas: [],
  updateStoreNetworks: () => {},
});

const AppProvider = ({ children }) => {
  const [redes, setRedes] = useState([]);
  const [lojas, setLojas] = useState([]);

  const updateStoreNetworks = async () => {
    const response = await api.get("/api/usuario/app");

    const { redes, lojas } = response.data;

    setRedes(redes);
    setLojas(lojas);
  };

  useEffect(() => {
    async function loadStorageData() {
      const tokenData = JSON.parse(localStorage.getItem("smartData"));
      if (tokenData && tokenData.token) {
        api.defaults.headers.Authorization = `Bearer ${tokenData.token}`;
        await updateStoreNetworks();
      }
    }

    loadStorageData();
  }, []);

  return (
    <AppContext.Provider
      value={{
        redes: redes,
        lojas: lojas,
        updateStoreNetworks: updateStoreNetworks,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

function useApp() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useApp must be used within an AppProvider.");
  }

  return context;
}

function withAppContext(Component) {
  return (props) => {
    const app = useApp();

    return <Component app={app} {...props} />;
  };
}

export { AppProvider, useApp, withAppContext };
