import React from "react";

// Containers
const DefaultLayout = React.lazy(() => import("../containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("../views/Pages/Login"));
const ForgotPassword = React.lazy(() =>
  import("../views/Pages/ForgotPassword")
);
const Page404 = React.lazy(() => import("../views/Pages/Page404"));
const Page500 = React.lazy(() => import("../views/Pages/Page500"));

const pages = [
  { path: "/login", exact: true, name: "Login", render: Login },
  {
    path: "/recuperar_senha",
    exact: true,
    name: "Recuperar Senha",
    render: ForgotPassword,
  },
  { path: "/404", exact: true, name: "404", render: Page404 },
  { path: "/500", exact: true, name: "500", render: Page500 },
  {
    path: "/",
    name: "Home",
    render: DefaultLayout,
    privateRoute: true,
  },
];

export default pages;
