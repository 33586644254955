import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../services/API";
import { toast } from "react-toastify";

const AuthContext = createContext({
  token: "",
  usuario: {},
  isLoggedIn: false,
  isLoading: false,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState({});
  const [token, setToken] = useState("");

  const login = async (username, password) => {
    try {
      const response = await api.post("/api/usuario/autenticar", {
        usuario: username,
        senha: password,
      });

      const { token, usuario } = response.data;

      localStorage.setItem(
        "smartData",
        JSON.stringify({
          token,
          usuario,
        })
      );
      api.defaults.headers.Authorization = `Bearer ${token}`;

      setUsuario(usuario);
      setToken(token);
      setLoggedIn(true);

      toast(`Olá, ${usuario.nome}!`);

      return true;
    } catch (error) {
      toast(error.message);
      return false;
    }
  };

  const logout = (message) => {
    setLoggedIn(false);
    setUsuario({});
    setToken("");
    localStorage.removeItem("smartData");
    if (message) {
      toast(message);
    }
  };

  useEffect(() => {
    async function loadStorageData() {
      const storedData = JSON.parse(localStorage.getItem("smartData"));
      if (storedData && storedData.token && storedData.usuario) {
        try {
          api.defaults.headers.Authorization = `Bearer ${storedData.token}`;
          await api.get("/api/usuario/validar");

          setLoggedIn(true);
          setUsuario(storedData.usuario);
          setToken(storedData.token);
        } catch (error) {
          setLoggedIn(false);
          setUsuario({});
          setToken("");
          localStorage.removeItem("smartData");
        }
      }
      setLoading(false);
    }

    loadStorageData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: loggedIn,
        isLoading: loading,
        login: login,
        logout: logout,
        usuario: usuario,
        token: token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

function withAuth(Component) {
  return (props) => {
    const auth = useAuth();

    return <Component auth={auth} {...props} />;
  };
}

export { AuthProvider, useAuth, withAuth };
