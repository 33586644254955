import React from "react";
import { Router } from "react-router-dom";
import { ToastContainer, Flip, toast } from "react-toastify";
import "./App.scss";
import { AuthProvider } from "./context/AuthContext";
import { AppProvider } from "./context/AppContext";
import { AppPages } from "./routes";
import historico from "./services/Historico";

const loading = () => (
  <div className="animated fadeIn pt-3 pb-3 text-center f-18">
    Carregando...
  </div>
);

export default function App() {
  return (
    <AuthProvider>
      <AppProvider>
        <Router history={historico}>
          <React.Suspense fallback={loading()}>
            <AppPages />
            <ToastContainer
              transition={Flip}
              autoClose={3000}
              position={toast.POSITION.BOTTOM_CENTER}
            />
          </React.Suspense>
        </Router>
      </AppProvider>
    </AuthProvider>
  );
}
