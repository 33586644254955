import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import pages from "./pages";
import components from "./components";

function RouteAuth({ component: Component, privateRoute = false, ...props }) {
  const { isLoggedIn, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="animated fadeIn pt-3 pb-3 text-center f-18">
        Carregando...
      </div>
    );
  }

  if (!isLoggedIn && privateRoute) {
    return <Redirect to="/login" />;
  }

  if (isLoggedIn && !privateRoute) {
    return <Redirect to="/" />;
  }

  return <Route {...props} component={Component} />;
}

const appPages = pages.map((route, index) => (
  <RouteAuth
    key={index}
    path={route.path}
    exact={route.exact}
    name={route.name}
    component={route.render}
    privateRoute={route.privateRoute}
  ></RouteAuth>
));

const appComponents = components.map((component, index) => (
  <Route
    key={index}
    path={component.path}
    exact={component.exact}
    name={component.name}
    component={component.component}
  ></Route>
));

export function AppPages() {
  return <Switch>{appPages}</Switch>;
}

export function AppComponents() {
  return <Switch>{appComponents}</Switch>;
}
