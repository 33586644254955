import axios from "axios";
import "dotenv/config";

const api = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

const errorResponseHandler = (error) => {
  if (
    error.config.hasOwnProperty("errorHandle") &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }

  if (
    !error.response ||
    error.response.status === 404 ||
    error.response.status === 502
  ) {
    error.message = "Aconteceu algum problema, tente novamente mais tarde.";
  } else {
    error.message = error.response.data.erro;
  }

  return Promise.reject(error);
};

api.interceptors.response.use((response) => response, errorResponseHandler);

export default api;
