import React from "react";

const GridLojas = React.lazy(() => import("../components/GridLojas/index"));
const FormUsuario = React.lazy(() => import("../components/FormUsuario/index"));
const GridUsuarios = React.lazy(() =>
  import("../components/GridUsuarios/index")
);

const GridPremios = React.lazy(() => import("../components/GridPremios/index"));
const FormPremio = React.lazy(() => import("../components/FormPremio/index"));

const Cadastros = React.lazy(() => import("..//components/Cadastros"));
const FormRede = React.lazy(() => import("../components/FormRede"));
const FormLoja = React.lazy(() => import("../components/FormLoja"));

const Campanhas = React.lazy(() => import("..//components/Campanhas"));
const Dashboard = React.lazy(() => import("..//components/Dashboard"));
const GridClientes = React.lazy(() => import("../components/GridClientes"));
const GridProdutos = React.lazy(() => import("../components/GridProdutos"));
const GridTransacoes = React.lazy(() => import("../components/GridTransacoes"));
const GridCampanhas = React.lazy(() => import("../components/GridCampanhas"));
const GridResgates = React.lazy(() => import("../components/GridResgates"));
const GridLotesCartaoPresente = React.lazy(() =>
  import("../components/GridLotesCartaoPresente")
);
const GridCartaoPresente = React.lazy(() =>
  import("../components/GridCartaoPresente")
);
const RelatorioEmail = React.lazy(() =>
  import("../components/GridCampanhas/RelatorioEmail")
);
const RelatorioSMS = React.lazy(() =>
  import("../components/GridCampanhas/RelatorioSMS")
);
const DetalhesCliente = React.lazy(() =>
  import("../components/GridClientes/DetalhesCliente")
);
const GridProdutoClientes = React.lazy(() =>
  import("../components/GridProdutos/GridProdutoClientes")
);
const GridRedes = React.lazy(() => import("../components/GridRedes"));

const components = [
  {
    path: "/campanhas/sms/relatorio",
    name: "Relatório",
    component: RelatorioSMS,
    exact: true,
  },
  {
    path: "/campanhas/email/relatorio",
    name: "Relatório",
    component: RelatorioEmail,
    exact: true,
  },
  {
    path: "/campanhas/resultados",
    name: "Resultado das Campanhas",
    component: GridCampanhas,
    exact: true,
  },
  {
    path: "/campanhas/criar",
    name: "Criar Campanha",
    component: Campanhas,
    exact: true,
  },
  {
    path: "/campanhas",
    name: "Campanhas",
    component: null,
    exact: true,
  },
  {
    path: "/cliente/detalhes",
    name: "Detalhes",
    component: DetalhesCliente,
    exact: true,
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: GridClientes,
    exact: true,
  },
  {
    path: "/produtos/clientes",
    name: "Clientes desse Produto",
    component: GridProdutoClientes,
    exact: true,
  },
  {
    path: "/produtos",
    name: "Produtos",
    component: GridProdutos,
    exact: true,
  },
  {
    path: "/transacoes",
    name: "Transações",
    component: GridTransacoes,
    exact: true,
  },
  {
    path: "/resgates",
    name: "Resgates",
    component: GridResgates,
    exact: true,
  },
  {
    path: "/cartao-presente/lotes/:numero/cartoes",
    name: "Cartões",
    component: GridCartaoPresente,
    exact: true,
  },
  {
    path: "/cartao-presente/lotes/:numero/",
    name: "",
    component: null,
    exact: true,
  },
  {
    path: "/cartao-presente/lotes",
    name: "Lotes",
    component: GridLotesCartaoPresente,
    exact: true,
  },
  {
    path: "/cartao-presente/",
    name: "",
    component: null,
    exact: true,
  },
  {
    path: "/cadastros/redes/:id/editar",
    name: "Editar",
    component: FormRede,
  },
  {
    path: "/cadastros/redes/criar",
    name: "Cadastrar",
    component: FormRede,
    exact: true,
  },
  {
    path: "/cadastros/redes",
    name: "Redes",
    exact: true,

    component: GridRedes,
  },
  {
    path: "/cadastros/redes/:id",
  },
  {
    path: "/cadastros/lojas/:id/editar",
    name: "Editar",
    component: FormLoja,
  },
  {
    path: "/cadastros/lojas/criar",
    name: "Cadastrar",
    component: FormLoja,
    exact: true,
  },
  {
    path: "/cadastros/lojas",
    name: "Lojas",
    exact: true,

    component: GridLojas,
  },
  {
    path: "/cadastros/lojas/:id",
  },
  {
    path: "/cadastros/usuarios/:id/editar",
    name: "Editar",
    component: FormUsuario,
  },
  {
    path: "/cadastros/usuarios/criar",
    name: "Cadastrar",
    component: FormUsuario,
    exact: true,
  },
  {
    path: "/cadastros/usuarios",
    name: "Usuários",
    exact: true,

    component: GridUsuarios,
  },
  {
    path: "/cadastros/usuarios/:id",
  },
  {
    path: "/cadastros/premios/:id/editar",
    name: "Editar",
    component: FormPremio,
  },
  {
    path: "/cadastros/premios/criar",
    name: "Cadastrar",
    component: FormPremio,
    exact: true,
  },
  {
    path: "/cadastros/premios",
    name: "Prêmios",
    exact: true,

    component: GridPremios,
  },
  {
    path: "/cadastros/premios/:id",
  },
  {
    path: "/cadastros",
    name: "Cadastros",
    component: Cadastros,
    exact: true,
  },
  { path: "/", component: Dashboard, name: "Dashboard" },
];

export default components;
